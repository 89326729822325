@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: #fcfcfc;
  box-sizing: border-box;
}

.copyright-data {
  color: white;
  text-align: end;
  padding-right: 80px;
  padding-bottom: 10px;
}
/* ==================== Navbar ===============  */
.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 70px;
  background-color: #004aad;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-container {
  flex: 1;
  display: flex;
  align-items: center;
}

.logo {
  width: 149px;
  height: auto;
  animation: zoom-in-out 3s ease-in-out infinite;
}

@keyframes zoom-in-out {
  0%,
  100% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1.1);
  }
}

/* .social-media {
  display: flex;
  gap: 20px;
  flex: 1;
  justify-content: center;
}

.social-icon {
  color: white;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
} */

.social-media {
  display: none; /* Default state: hidden */
}

.social-icon {
  margin: 0 10px;
  color: white;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.social-icon:hover {
  transform: scale(1.1);
  color: burlywood;
}

.menu-icon {
  display: none;
}

.nav-elements {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin-top: 10px;
}

.nav-elements ul li {
  margin-left: 30px;
}

.nav-elements ul a {
  font-size: 20px;
  font-weight: 400;
  color: white;
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.nav-elements ul a:hover {
  color: #e5ff00;
  transform: scale(1.1);
}

.nav-elements ul a.active {
  color: #e5ff00;
  font-weight: 500;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #e5ff00;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 70px;
    background-color: #fef7e5;
    width: 0;
    height: calc(100vh - 70px);
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    z-index: 2;
  }

  .nav-elements.active {
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    background-color: #000;
  }

  .nav-elements ul {
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }

  .nav-elements ul a {
    font-size: 24px;
    padding: 10px 0;
  }
}

@media (min-width: 900px) {
  .social-media {
    display: flex; /* Show when width is 600px or more */
    justify-content: center;
    align-items: center;
  }
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0rem !important;
}
/* ========================Home Section 1 ==================  */

.firstSection {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  /* Aspect ratio 16:9 */
  position: relative;
}

.responsiveIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* ========================Home Section 2 ==================  */

.grid-container {
  margin: 50px auto;
  width: 80%;
  height: 90vh;
  display: grid;
  grid-auto-columns: 1fr;
  gap: 1em;
  grid-template-areas:
    "one one two five five"
    "one one three five five"
    "four four four five five";
}

.grid-item {
  border-radius: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 10px 10px 18px;
}

.grid-item:nth-child(1) {
  grid-area: one;
  background-color: #222e50;
}

.grid-item:nth-child(2) {
  grid-area: two;
  background-color: #007991;
}

.grid-item:nth-child(3) {
  grid-area: three;
  background-color: #439a86;
}

.grid-item:nth-child(4) {
  grid-area: four;
  background-color: #f6511d;
}

.grid-item:nth-child(5) {
  grid-area: five;
  background-color: #c1292e;
}

@media (max-width: 940px) {
  .grid-container {
    grid-template-areas:
      "one one two five five"
      "three three three five five"
      "four four four four four";
  }
}

@media (max-width: 640px) {
  .grid-container {
    grid-template-areas:
      "one one two two two"
      "three three three five five"
      "four four four four four";
  }
}

@media (max-width: 460px) {
  .grid-container {
    grid-template-areas:
      "one"
      "two"
      "three"
      "four"
      "five";
  }
}

.gridone {
  font-size: 2vw;
  /* Responsive font size */
}

.gridtwo {
  font-size: 1.8vw;
  /* Responsive font size */
}

.gridthree {
  font-size: 1.5vw;
  /* Responsive font size */
}

.gridfour {
  font-size: 2.5vw;
  /* Responsive font size */
}

.gridfive {
  font-size: 2.2vw;
  /* Responsive font size */
}

@media (max-width: 940px) {
  .gridone {
    font-size: 3vw;
  }

  .gridtwo {
    font-size: 2.8vw;
  }

  .gridthree {
    font-size: 2.5vw;
  }

  .gridfour {
    font-size: 3.5vw;
  }

  .gridfive {
    font-size: 3.2vw;
  }
}

@media (max-width: 640px) {
  .gridone {
    font-size: 4vw;
  }

  .gridtwo {
    font-size: 3.8vw;
  }

  .gridthree {
    font-size: 3.5vw;
  }

  .gridfour {
    font-size: 3.2vwvw;
  }

  .gridfive {
    font-size: 3.2vwvw;
  }
}

@media (max-width: 460px) {
  .gridone {
    font-size: 5vw;
  }

  .gridtwo {
    font-size: 4.8vw;
  }

  .gridthree {
    font-size: 4.5vw;
  }

  .gridfour {
    font-size: 5.5vw;
  }

  .gridfive {
    font-size: 5.2vw;
  }
}

/* ================ Home Section 3 ========= */

/* The actual timeline (the vertical ruler) */
.main-timeline-4 {
  position: relative;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-4::after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: #bbb;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline-4 {
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline-4::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -11px;
  background-color: #bbb;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left-4 {
  padding: 0px 40px 20px 0px;
  left: 0;
}

/* Place the container to the right */
.right-4 {
  padding: 0px 0px 20px 40px;
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left-4::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 30px;
  border: medium solid rgba(37, 117, 252, 1);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent rgba(37, 117, 252, 1);
}

/* Add arrows to the right container (pointing left) */
.right-4::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 30px;
  border: medium solid rgba(245, 87, 108, 1);
  border-width: 10px 10px 10px 0;
  border-color: transparent rgba(245, 87, 108, 1) transparent transparent;
}

/* Fix the circle for containers on the right side */
.right-4::after {
  left: -14px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .main-timeline-4::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-4 {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-4::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left-4::after,
  .right-4::after {
    left: 18px;
  }

  .left-4::before {
    right: auto;
  }

  /* Make all right containers behave like the left ones */
  .right-4 {
    left: 0%;
  }
}

.gradient-custom {
  /* fallback for old browsers */
  background: #6a11cb;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    rgba(106, 17, 203, 1),
    rgba(37, 117, 252, 1)
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to right,
    rgba(106, 17, 203, 1),
    rgba(37, 117, 252, 1)
  );
}

.gradient-custom-4 {
  /* fallback for old browsers */
  background: #f093fb;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to left,
    rgb(240 92 134),
    rgba(245, 87, 108, 1)
  );
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to left, rgb(240 92 134), rgba(245, 87, 108, 1));
}

.why {
  margin: 20px auto;
  font-size: 38px;
  font-family: inherit;
  color: white;
  border: 2px solid whitesmoke;
  border-radius: 14px;
  /* background-color: red; */
  background-color: #004aad;
  padding: 22px;
  text-align: center;
}

/* ============== Home Section 4 ==================== */

.fourthSection {
  background-color: #004aad;
}
.cardsData {
  display: flex;
  flex-wrap: wrap;
  /* Allows the items to wrap onto multiple lines */
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 10px;
}

.cardss {
  flex: 1 1 300px;
  /* Flex-grow, flex-shrink, flex-basis */
  max-width: 300px;
  margin: 10px;
}

@media (max-width: 768px) {
  .card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

/* ========================== Home Section 5===================== */

/* Heading.css */

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap'); */

.fifthSection {
  background-color: #004aad;
}
.heading-container {
  font-family: "Poppins", sans-serif;
  font-size: 2.5em;
  color: #ffffff;
  text-align: center;
  padding: 20px;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 150, 0.8),
    rgba(0, 204, 255, 0.8)
  );
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* margin: 20px 0; */
  transition: transform 0.3s ease-in-out;
  width: 80%;
  margin: 0px auto;
}

.heading-container:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .heading-container {
    font-size: 2em;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .heading-container {
    font-size: 1.5em;
    padding: 10px;
  }
}

.subheading-container {
  font-family: "Poppins", sans-serif;
  font-size: 1.5em;
  color: #333333;
  text-align: center;
  padding: 20px;
  background: #f9f9f9;
  border-left: 5px solid rgba(255, 0, 150, 0.8);
  border-radius: 5px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  margin: 20px auto;
  transition: background-color 0.3s ease-in-out;
}

.subheading-container:hover {
  background-color: #e9e9e9;
}

@media (max-width: 768px) {
  .subheading-container {
    font-size: 1.2em;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .subheading-container {
    font-size: 1em;
    padding: 10px;
  }
}

/* TopicsGrid.css */

.grids {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 10px 96px;

  /* padding: 10px; */
  background-color: #004aad;
}

.grid-items {
  background: linear-gradient(135deg, #ff6f61, #ffb5a7);
  padding: 12px;
  border-radius: 10px;
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.grid-items a {
  color: #ffffff;
  text-decoration: underline;
  transition: color 0.3s;
}

.grid-items:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.grid-items a:hover {
  color: #ffe5d9;
}

.customButton {
  background: linear-gradient(145deg, #4caf50, #81c784);
  color: white;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 16px;
  padding: 8px 15px;
  border: none;
  border-radius: 30px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.customButton:hover {
  background: linear-gradient(145deg, red, #66bb6a);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.customButton:active {
  transform: translateY(1px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .customButton {
    font-size: 1rem;
    padding: 12px 24px;
    border-radius: 25px;
  }
}

@media (max-width: 480px) {
  .customButton {
    font-size: 0.9rem;
    padding: 10px 20px;
    border-radius: 20px;
  }
}

/* Modal.css */
.customModal {
  background: linear-gradient(145deg, #f5f5f5, #e0e0e0);
  border-radius: 15px;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  box-sizing: border-box;
  overflow: hidden;
}

.modalHeader {
  text-align: center;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.modalHeader h2 {
  font-family: "Helvetica Neue", sans-serif;
  color: #333;
}

.modalContent {
  font-family: "Arial", sans-serif;
}

.topicList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

.topicList li {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.topicList li:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.topicList li:nth-child(odd) {
  background: #f9f9f9;
}

/* Responsive Styles */
@media (min-width: 769px) {
  .topicList {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .customModal {
    padding: 15px;
  }

  .modalHeader h2 {
    font-size: 1.5rem;
  }

  .topicList li {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .customModal {
    padding: 10px;
  }

  .modalHeader h2 {
    font-size: 1.2rem;
  }

  .topicList li {
    padding: 6px;
    font-size: 0.9rem;
  }
}

/* @media (max-width: 480px) {
  .customModal {
    padding: 10px;
    max-height: 80vh;
    overflow-y: auto;
   
  }


  .modalHeader h2 {
    font-size: 1.2rem;
  }

  .topicList li {
    padding: 6px;
    font-size: 0.9rem;
  }
} */

/* ================= home section 6 -------------- */

.Apps {
  margin: 0 auto;
  padding: 20px;
  background-color: #004aad;
}

.animated-header {
  background: linear-gradient(135deg, #ff6f61 0%, #ff9671 100%);
  padding: 30px 0px;
  text-align: center;
  color: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  animation: fadeIn 2s ease-in-out;
  position: relative;
  overflow: hidden;
}

.animated-title {
  font-size: 3em;
  margin-bottom: 20px;
  animation: slideInFromLeft 1s ease-in-out;
  position: relative;
  z-index: 1;
  font-weight: 800;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.animated-subtitle {
  font-size: 3em;
  animation: slideInFromRight 1s ease-in-out;
  position: relative;
  z-index: 1;
  font-weight: 500;
}

.animated-header::before,
.animated-header::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  background: rgba(255, 255, 255, 0.2);
  z-index: 0;
  transform: skewX(-30deg);
}

.animated-header::after {
  animation: slideBackground 3s ease-in-out infinite;
}

@keyframes slideBackground {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}

.teachers-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
  animation: fadeIn 2s ease-in-out;
}

.teacher-card {
  background-color: white;
  border: none;
  border-radius: 15px;
  width: 280px;
  margin: 20px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  animation: zoomIn 1s ease-in-out;
}

.teacher-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.teacher-image {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}

.teacher-card:hover .teacher-image {
  transform: rotate(10deg) scale(1.1);
}

.teacher-name {
  font-size: 1.8em;
  margin-bottom: 10px;
  font-weight: bold;
  color: #ff6f61;
}

.teacher-subject {
  font-size: 1.2em;
  color: #555;
}

/* Keyframes for animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes zoomIn {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .teacher-card {
    width: 90%;
    margin: 10px 0;
  }
}

/* ============= home section 7 ===================== */
/* General Container Styling */
.containerss {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  /* min-height: 100vh; */
}

.offer-section {
  background-color: #002b5b;
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  max-width: 1134px;
  /* max-width: 500px; */
  width: 100%;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.value-text,
.normal-price-text,
.old-price,
.new-price,
.limited-offer,
.hurry-text {
  font-size: 18px;
  margin-bottom: 10px;
}

.value-text span,
.normal-price-text span {
  font-weight: bold;
  color: #00ff00;
}

.today-price {
  margin: 20px 0;
}

.old-price {
  text-decoration: line-through;
  color: #ff0000;
}

.new-price {
  font-size: 24px;
  color: #00ff00;
}

.button {
  background-color: #00b300;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px 0;
  transition: background-color 0.3s, transform 0.2s;
  width: 100%;
  max-width: 300px;
}

.button:hover {
  background-color: #009900;
  transform: scale(1.05);
}

.button:active {
  transform: scale(0.95);
}

.note {
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  margin: 20px 0;
  border: 2px dashed red;
}

.note-text {
  font-size: 16px;
}

@media (max-width: 1200px) {
  .title {
    font-size: 22px;
  }

  .value-text,
  .normal-price-text,
  .old-price,
  .new-price,
  .limited-offer,
  .hurry-text {
    font-size: 17px;
  }

  .new-price {
    font-size: 22px;
  }

  .button {
    padding: 9px 18px;
    font-size: 17px;
  }

  .note-text {
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  .title {
    font-size: 20px;
  }

  .value-text,
  .normal-price-text,
  .old-price,
  .new-price,
  .limited-offer,
  .hurry-text {
    font-size: 16px;
  }

  .new-price {
    font-size: 20px;
  }

  .button {
    padding: 8px 16px;
    font-size: 16px;
  }

  .note-text {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .containerss {
    padding: 10px;
  }

  .offer-section {
    padding: 15px;
  }

  .title {
    font-size: 18px;
  }

  .value-text,
  .normal-price-text,
  .old-price,
  .new-price,
  .limited-offer,
  .hurry-text {
    font-size: 14px;
  }

  .new-price {
    font-size: 18px;
  }

  .button {
    padding: 7px 14px;
    font-size: 15px;
  }

  .note-text {
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 16px;
  }

  .value-text,
  .normal-price-text,
  .old-price,
  .new-price,
  .limited-offer,
  .hurry-text {
    font-size: 12px;
  }

  .new-price {
    font-size: 16px;
  }

  .button {
    padding: 6px 12px;
    font-size: 14px;
  }

  .note-text {
    font-size: 12px;
  }
}

/* ============= home section 8===================== */
.App-header {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  color: black;
}

/* ============= home section 9===================== */
.secondSection {
  padding: 40px 20px;
  background-color: #f4f4f9;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.Data {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  animation: slideInLeft 1s ease-out;
}

.Data:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.card-body {
  padding: 20px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: #444444;
  font-size: 18px;
  line-height: 1.6;
  animation: fadeIn 1s ease-in-out, textSlideIn 1.5s ease-in-out;
}

@media (max-width: 768px) {
  .card-body {
    font-size: 16px;
  }
}

@media (min-width: 769px) {
  .card-body {
    font-size: 20px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes textSlideIn {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* ------------------- Home section 9 --------------- */

/* src/Testimonial.css */
.testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
}

.testimonial-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  max-width: 600px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.quote {
  font-size: 1.2rem;
  font-style: italic;
  color: #333;
  margin-bottom: 15px;
}

.author {
  font-size: 1rem;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 5px;
}

.role {
  font-size: 0.9rem;
  color: #555;
}

@media (min-width: 768px) {
  .testimonial-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* ------------------------ Fixed btn ---------------- */

.custom-payment-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.custom-payment-button {
  background-color: #ffffff;
  /* color: #ffffff; */
  /* padding: 10px 30px; */
  /* font-size: 18px; */
  border: none;
  /* border-radius: 50px; */
  cursor: pointer;
  /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
  /* box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); */
}

.custom-payment-button:hover {
  transform: scale(1.05);
  /* box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2); */
}

.custom-payment-button:active {
  transform: scale(0.95);
}

.boom-effect {
  animation: boom 0.5s forwards;
}

@keyframes boom {
  0% {
    transform: scale(1);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.3);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .custom-payment-button {
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 40px;
  }

  .custom-payment-container {
    bottom: 15px;
    right: 15px;
  }
}

@media (max-width: 480px) {
  .custom-payment-button {
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 35px;
  }

  .custom-payment-container {
    bottom: 10px;
    right: 10px;
  }
}

/* -----------------------------------   blog -  */
