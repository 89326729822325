/* Main container */
.notes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(135deg, #f0f4f8, #dfe9f3);
  padding: 40px 20px;
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
}

/* Page title */
.page-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

/* Cards container (Vertical layout) */
.cards-vertical {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
}

/* Individual vertical card */
.vertical-card {
  text-decoration: none;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

/* Button */
.visit-notes-button {
  margin-top: 10px;
  padding: 10px 20px;
  background: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease, transform 0.2s ease;
}

.visit-notes-button:hover {
  background: #555;
  transform: scale(1.05);
}

/* Hover Effects */
.vertical-card:hover {
  background: linear-gradient(135deg, #ffffff, #f1f1f1);
  transform: scale(1.03);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.vertical-card:hover .vertical-card-header {
  height: 100px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0.1)
  );
}

/* .vertical-card:hover .vertical-card-content h2 {
  color: #ffffff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
} */

.vertical-card:hover .vertical-card-content p {
  color: #666666;
}

/* Card header (colored block) */
.vertical-card-header {
  height: 8px;
  width: 100%;
}

/* Card content */
.vertical-card-content {
  padding: 20px;
  text-align: left;
  transition: all 0.3s ease;
}

.vertical-card-content h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #333;
  transition: color 0.3s ease;
}

.vertical-card-content p {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  transition: color 0.3s ease;
}

/* Responsive design */
@media (max-width: 768px) {
  .page-title {
    font-size: 2rem;
  }

  .vertical-card-content h2 {
    font-size: 1.5rem;
  }

  .vertical-card-content p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .cards-vertical {
    gap: 15px;
  }

  .vertical-card-content h2 {
    font-size: 1.3rem;
  }

  .vertical-card-content p {
    font-size: 0.8rem;
  }
}
