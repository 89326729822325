.timer {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .time-block {
    background-color: #00ff00;
    color: #002b5b;
    padding: 10px 10px;
    margin: 0 5px;
    border-radius: 5px;
    font-size: 18px;
  }
  