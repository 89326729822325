* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
  }
  
  .contact-page {
    display: flex;
    flex-direction: column;
    height: 91vh;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
  }
  
  .image-container {
    flex: 1;
    background: url('../assets/9358213928.png') no-repeat center center/cover;
  }
  
  .contact-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    background: white;
    animation: slideIn 0.5s ease-in-out;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  h1 {
    margin-bottom: 20px;
    color: #333;
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .contact-form .form-group {
    margin-bottom: 15px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 5px;
    color: #666;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    border-color: #6e8efb;
    outline: none;
  }
  
  .contact-form textarea {
    resize: vertical;
  }
  
  .submit-btn {
    display: inline-block;
    background: #6e8efb;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .submit-btn:hover {
    background: #575ed0;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background: white;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
  }
  
  /* Media Queries */
  @media (min-width: 768px) {
    .contact-page {
      flex-direction: row;
    }
  }
  
  @media (max-width: 767px) {
    .contact-container {
      padding: 15px;
    }
  
    .contact-form input,
    .contact-form textarea {
      font-size: 14px;
    }
  
    .submit-btn {
      padding: 8px 16px;
    }
  
    .popup-content {
      padding: 20px;
    }
  
    .close-btn {
      top: 5px;
      right: 5px;
      font-size: 16px;
    }
  }
  