/* Container */
.html-page {
  display: flex;
  height: 100vh;
  font-family: "Arial", sans-serif;
  color: #333;
}

/* Sidebar */
/* Sidebar */
.sidebar {
  width: 20%;
  padding: 20px;
  border-right: 1px solid #ccc;
  background: #f8f9fa;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Enable vertical scrolling */
  height: 100vh; /* Ensure it takes up the full height of the viewport */
}

.sidebar h3 {
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 15px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  transition: background 0.3s, transform 0.2s;
}

.sidebar li:hover {
  background: #e9ecef;
  transform: scale(1.02);
}

.sidebar li.active {
  background: #007bff;
  color: #fff;
}

/* Custom Scrollbar Styling */
.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 4px;
}

.sidebar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Content Area */
.content-area {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  background: #ffffff;
}

.content-area h2 {
  font-size: 2rem;
  color: #343a40;
  margin-bottom: 20px;
}

.content-area h3 {
  font-size: 1.5rem;
  color: #495057;
  margin-top: 20px;
}

.content-area p {
  font-size: 1rem;
  line-height: 1.6;
  margin: 10px 0;
}

.content-area p a {
  color: #007bff;
  text-decoration: none;
}

.content-area p a:hover {
  text-decoration: underline;
}

/* Scrollable Content */
.content-area::-webkit-scrollbar {
  width: 8px;
}

.content-area::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 4px;
}

.content-area::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
