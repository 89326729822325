.accordion {
  /* max-width: 600px; */
  /* margin: 20px auto; */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.accordion-item {
  border-bottom: 1px solid #ccc;
}

.accordion-title {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  background: #f7f7f7;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

.accordion-title:hover {
  background: #e0e0e0;
}

.accordion-title.active {
  background: #007bff;
  color: #fff;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-content.active {
  max-height: 200px;
  padding: 15px;
  background: #f9f9f9;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

@media (max-width: 600px) {
  .accordion {
    width: 100%;
    margin: 10px 0;
  }

  .accordion-title {
    font-size: 16px;
    padding: 10px;
  }

  .accordion-content {
    padding: 10px;
  }
}
